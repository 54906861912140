import React from 'react'
import { Container } from 'semantic-ui-react'
import Layout from '../components/Layouts/cakeDefault'
import { HOTLINE_ADVICE } from '../utils/constants.js'

export default function PolicyPage() {
  return (
    <Layout
      endPoint={'chinh-sach-bao-mat'}
      title={'Savor Cake | Privacy Policy'}
    >
      <Container text style={{ marginTop: 20 }}>
        <h2>Chính sách riêng tư & bảo mật</h2>
        <p>
          Bảo mật thông tin bạn là một trong những ưu tiên nhằm tạo trải nghiệm
          tốt nhất cho bạn tại Savor.vn. Chúng tôi hiểu sử dụng hợp lý và bảo
          mật thông tin sẽ thể hiện sự quan tâm của Savor.vn dành cho bạn. Vì
          thế, Savor.vn cam kết việc sử dụng thông tin trên sẽ chỉ nhằm nâng cao
          chất lượng dịch vụ và tạo môi trường mua sắm trực tuyến an tòan, tiện
          lợi tại Savor.vn. Cụ thể:
        </p>
        <ul>
          <li>
            <strong> Phạm vi thu thập thông tin: </strong>bao gồm thông tin cá
            nhân được bạn cung cấp khi đăng ký tài khoản, các giao dịch bạn đã
            thực hiện thành công hoặc chưa thành công với Savor.vn.
          </li>
          <li>
            <strong>Mục đích thu thập thông tin</strong>:
            <ul>
              <li>
                Cung cấp một số tiện ích, nâng cao chất lượng dịch vụ hỗ trợ bạn
              </li>
              <li>
                Giải quyết các vấn đề, tranh chấp phát sinh liên quan đến việc
                sử dụng website Savor.vn
              </li>
              <li>Ngăn chặn những hoạt động vi phạm pháp luật Việt Nam</li>
            </ul>
          </li>
          <li>
            <strong>Phạm vi sử dụng thông tin: </strong>Các thông tin thu thập
            được sẽ được dùng để hỗ trợ trong quá trình xử lý đơn hàng (VD: đối
            soát đơn hàng đã mua để giải quyết khiếu nại, liên hệ trong các
            trường hợp có thay đổi trong đơn hàng), các chương trình khuyến mãi,
            hậu mãi của Savor.vn.
          </li>
          <li>
            <strong>Cam kết bảo mật thông tin:</strong> Savor.vn cam đoan sẽ
            không bán, chia sẻ dẫn đến làm lộ thông tin cá nhân của bạn vì mục
            đích thương mại vi phạm cam kết của chúng tôi ghi trong chính sách
            bảo mật này. Tất cả thông tin giao dịch giữa bạn và Savor.vn sẽ được
            bảo mật qua phần mềm Secure Sockets Layer (SSL) bằng cách mã hóa tất
            cả thông tin bạn nhập vào.
          </li>
          <li>
            Tuy nhiên, bạn không nên trao đổi những thông tin thanh toán, giao
            nhận của mình cho 1 bên thứ 3 nào khác để tránh rò rỉ thông tin. Khi
            sử dụng chung máy tính với nhiều người, vui lòng thoát khỏi tài
            khoản mỗi khi không sử dụng dịch vụ của Savor.vn nữa để tự bảo vệ
            thông tin về mật khẩu truy cập của mình.
          </li>
          <li>
            <strong>Thời gian lưu trữ thông tin:</strong> Các thông tin do bạn
            đăng ký với Savor.vn được lưu trữ trên hệ thống cho đến khi website
            ngừng hoạt động, hoặc đến khi bạn đề xuất được xoá toàn bộ dữ liệu
            cá nhân (bao gồm các thông tin cơ bản khi đăng ký tài khoản, các
            lịch sử giao dịch).
          </li>
          <li>
            <strong>Địa chỉ đơn vị thu thập lưu giữ thông tin</strong>: Toàn bộ
            thông tin của bạn được lưu giữ trên máy chủ đặt tại một bên cung cấp
            thứ ba với điều khoản chỉ được phép truy cập bởi Savor.vn hoặc một
            tổ chức có thẩm quyền khác theo quy định của pháp luật. Các thông
            tin mua bán hàng được truy cập tại trụ sở của Savor.vn tại số 108
            Nguyễn Chí Thanh, phường Láng Thượng, quận Đống Đa, Hà Nội.
          </li>
          <li>
            <strong>Phương tiện chính sửa dữ liệu cá nhân:</strong> Bạn có thể
            truy cập vào trang Savor.vn/me để thay đổi thông tin vào bất cứ lúc
            nào, hoặc gọi điện đến số hot line {HOTLINE_ADVICE} để yêu cầu xóa
            hoàn toàn thông tin cá nhân trên Savor.vn
          </li>
          <li>
            Bạn tuyệt đối không được sử dụng bất kỳ hình thức nào để can thiệp
            vào hệ thống hay làm thay đổi cấu trúc dữ liệu. Chúng tôi nghiêm cấm
            việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can
            thiệp, phá hoại hay xâm nhập vào dữ liệu của hệ thống website. Mọi
            vi phạm sẽ bị tước bỏ mọi quyền lợi cũng như sẽ bị truy tố trước
            pháp luật nếu cần thiết. Mọi thông tin của bạn tại Savor.vn sẽ được
            chúng tôi bảo mật nhưng trong trường hợp pháp luật yêu cầu, chúng
            tôi buộc phải cung cấp thông tin này cho cơ quan pháp luật
          </li>
          <li>
            Savor.vn hiểu rằng quyền lợi của bạn trong việc bảo vệ thông tin cá
            nhân cũng chính là trách nhiệm của chúng tôi nên trong bất kỳ trường
            hợp có thắc mắc, góp ý nào liên quan đến chính sách bảo mật của
            Savor.vn, vui lòng liên hệ qua số điện thoại: {HOTLINE_ADVICE} hoặc
            email: info@savor.vn
          </li>
        </ul>
      </Container>
    </Layout>
  )
}
